:root {
  --main-color: teal;
}
* {
  font-family: "Roboto", sans-serif;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
  margin: 0;
}
img {
  width: 100%;
  height: auto;
}
.image-container {
  position: relative;
}
.image-container .header-name {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 0;
  text-transform: uppercase;
  text-shadow: 0 2px 10px rgb(0 0 0 / 50%);
  z-index: 1;
  text-align: center;
}
.image-container .header-name:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--main-color);
  opacity: 0.8;
  z-index: -1;
}
.image-container img {
  position: relative;
}
.image-container {
  position: relative;
}

.nav-link {
  padding: 0px 60px;
}

.mainNav {
  background-color: #17181b;
  padding: 0;
  width: 200px;
  position: fixed;
  left: 0;
}

.mainNav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
}

header nav ul .nav-item {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 1px solid #777;
  text-align: left;
}

header nav ul .nav-item a {
  display: block;
  line-height: 45px;
  position: relative;
  color: #777;
}
header nav ul .nav-item a:hover {
  color: #777;
}
header nav ul .nav-item a span.nav-menu-icon {
  position: absolute;
  left: 30px;
  display: inline-block;
  font-size: 18px;
}
@media (min-height: 610px) {
  .nav-footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.nav-footer {
  margin-top: 2em;
  width: 100%;
}
.nav-footer .social li {
  display: inline-block;
  font-size: 18px;
  margin-right: 10px;
}
.nav-footer .social li a {
  color: #777;
  -webkit-transition: color 0.2s ease;
  -o-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
.nav-footer .social li a:hover {
  color: #fff;
}
.nav-footer .copy p {
  font-size: 12px;
  color: #777;
}
.nav-footer .copy p span {
  color: var(--main-color);
  font-weight: 900;
  cursor: pointer;
}
.wrapper {
  width: 100%;
  height: 100vh;
}
.social {
  text-align: center;
  padding: 0;
}

header .header-mobile .header-toggle {
  color: #fff;
  font-size: 20px;
  line-height: 50px;
  cursor: pointer;
  width: 50px;
  text-align: center;
  border-left: 1px solid #3b3c43;
  float: right;
}

header .header-mobile h2 {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 50px;
  white-space: nowrap;
}

header .header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  background-color: #17181b;
  z-index: 10;
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header .header-mobile h2 {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 50px;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  #main {
    position: absolute;
    left: 0px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  header .header-mobile {
    display: block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .NavOn {
    left: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .NavOf {
    left: -200px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}
