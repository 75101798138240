:root{
  --main-color:teal;
  }
.banner-section {
  background-image: url("./../../assets/HomeCover.jpg");
  background-size: cover;
  width: calc(100% - 200px);
  height: 100vh;
  position: relative;
  left: 200px;
  overflow: hidden;
}
.banner-section::before {
  content: "";
  position: absolute;
  background-color: #2125299d;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.banner-section .banner-content {
  z-index: 2;
  color: #fff;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  display: block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner-section .banner-content h1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 62px;
  font-weight: 500;
  color: #fff;
}
.banner-section .banner-content h1 span {
  font-weight: 700;
  color: var(--main-color);
  font-family: "Playfair Display", serif;
}
.banner-section .banner-content div {
  font-size: 28px;
}
.typicalAnimation {
  color: var(--main-color);
  font-weight: 900;
}

@media (max-width: 767px) {
  .banner-section {
    width: 100%;
    left: 0%;
  }
  .banner-section .banner-content h1 {
    font-size: 50px;
  }
  .banner-section .banner-content div {
    font-size: 22px;
  }
}
