:root {
  --main-color: teal;
}
.input {
  display: block;
  position: relative;
}
.contact-section {
  background-color: #2c2d2f;
  width: calc(100% - 200px);
  position: relative;
  left: 200px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .contact-section {
    left: 0;
    width: 100%;
    padding-top: 70px;
  }
}
.input__field {
  width: 100%;
  border: 2px solid #999;
  padding: 5px 10px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  z-index: 1;
  background-color: transparent;
  color: #ffffff;
}
.input__field.cf-error {
  border-color: #ff0000;
}

.input__field:focus {
  outline: none;
  border-color: #ffffff;
}

.input__label {
  font-weight: 500;
  position: absolute;
  top: 7px;
  left: 12px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 0;
  color: #ffffff;
  opacity: 0.2;
}

.input__field:focus + .input__label,
.input--filled .input__label {
  font-size: 14px;
  top: -25px;
  left: 0;
  opacity: 1;
}
.input__field.cf-error {
  border-color: #ff0000;
}
.input--filled .input__field {
  border-color: #ffffff;
}

textarea {
  resize: none;
  height: auto;
}

.btn-main:hover {
  text-decoration: none;
  color: #2c2d2f;
  background-color: var(--main-color);
}
.contact-section .contact-info {
  text-align: center;
}
.contact-section .contact-info .info-item .icon {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 30px;
  line-height: 1em;
  margin-top: 15px;
  margin-bottom: 15px;
  color: var(--main-color);
}
.contact-section .contact-info .info-item h5 {
  font-size: 18px;
  color: #fff;
}
.contact-section .contact-info .info-item h5 a:hover {
  text-decoration: none;
}
