:root{
  --main-color:teal;
  }
a,
a:hover,
a:focus {
  color: inherit;
}
p {
  line-height: 1.65;
}
img {
  width: 100%;
  height: auto;
}
.pt-page .section-container {
  max-width: 1040px;
  width: 90%;
  margin: 0 auto;
}
.about-section {
  background-color: #2c2d2f;
  width: calc(100% - 200px);
  position: relative;
  left: 200px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .about-section {
    left: 0;
    width: 100%;
    padding-top: 70px;
  }
}
.page-heading {
  margin-bottom: 50px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
}
.page-heading .icon {
  float: right;
  font-size: 38px;
  line-height: 1em;
  color: var(--main-color);
}
.page-heading h2 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 1em;
  text-transform: capitalize;
  color: var(--main-color);
  position: relative;
  display: inline-block;
}
.about-section .about h3 {
  font-size: 22px;
  font-weight: 700;
  color: white;
}
.about-section .about p {
  color: white;
}
.about-section .about .signature {
  width: 50%;
}
@media (max-width: 575px) {
  .about-section .about .signature {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .about-section .about-info {
    margin-top: 40px;
  }
}
.about-section .about .about-info ul li {
  line-height: 1em;
  margin-bottom: 15px;
  color: #ffffff;
}
@media (max-width: 991px) {
  .about-section .about-info ul li {
    display: inline-block;
    position: relative;
    margin: 0 5px;
    padding: 0 5px;
  }
}
.about-section .about .about-info ul li span.title {
  font-family: "Playfair Display", serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-bottom: 2px solid #ffffff;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  padding-bottom: 1px;
  color: #ffffff;
}
.about-info ul li span.title {
  border-color: var(--main-color) !important;
}
.about-section .about .about-info ul li span.title:after {
  content: ":";
  position: absolute;
  right: -10px;
}
.btn-main {
  border: 2px solid #ffffff;
  padding: 10px 20px;
  text-transform: uppercase;
  background-color: transparent;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  position: relative;
  line-height: 100%;
  letter-spacing: 1px;
  overflow: hidden;
}
.btn-main {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}

.btn-main:hover {
  text-decoration: none;
  background-color: var(--main-color);
}
.btn-main:focus {
  color: white;
}
.subheading {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}
.subheading h3 {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: 0.5px;
  position: relative;
  text-transform: uppercase;
  padding: 5px 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
  color: #ffffff;
}
.subheading h3 {
  border-color: var(--main-color) !important;
}
.subheading h3:after {
  position: absolute;
  content: "";
  width: 2px;
  height: 5px;
  background-color: var(--main-color);
  bottom: 0;
  right: 0;
}
.subheading h3:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 5px;
  background-color: var(--main-color);
  bottom: 0;
  left: 0;
}
.about-section .services .service-item {
  text-align: center;
  margin-bottom: 40px;
  position: relative;
}
.about-section .services .service-item .icon {
  font-size: 50px;
  margin-bottom: 10px;
  line-height: 1em;
  color: var(--main-color);
}
.about-section .services .service-item h4 {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.about-section .services .service-item p {
  font-size: 14.5px;
  line-height: 22px;
  margin-bottom: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #ffffff;
}
.clients .Skill-logo img {
  width: 100px;
  height: 100px;
  margin: auto;
  opacity: 0.6;
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
@media (max-width: 575px) {
  .clients .Skill-logo img {
    padding: 15px;
  }
}
.clients .Skill-logo img:hover {
  opacity: 1;
}
.testimonials .testimonial-item {
  position: relative;
  padding: 5px;
}
.testimonials .testimonial-item .testimonial-content {
  background-color: #17181b;
  color: #fff;
  width: 100%;
  -webkit-border-radius: 50px 0 50px 0;
  border-radius: 50px 0 50px 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  padding-right: 20px;
  font-size: 14px;
  position: relative;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
}
.testimonials .testimonial-item .testimonial-content:before {
  content: "\f10d";
  position: absolute;
  left: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 50px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: var(--main-color);
}
.testimonials .testimonial-item .testimonial-content p {
  margin-bottom: 0;
}
.testimonials .testimonial-item .testimonial-content:after {
  position: absolute;
  content: "";
  left: 15px;
  top: 100%;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 11px solid #17181b;
}
.testimonials .testimonial-item .testimonial-meta {
  margin-top: 20px;
  margin-left: 15px;
}
.testimonials .testimonial-item .testimonial-meta img {
  width: 60px;
  height: 60px;
  float: left;
  -webkit-border-radius: 20px 0 20px 0;
  border-radius: 20px 0 20px 0;
  margin-right: 10px;
}
.testimonials .testimonial-item .testimonial-meta .meta-info h4 {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.testimonials .testimonial-item .testimonial-meta .meta-info p {
  font-size: 14px;
  color: #fff;
}
