:root{
  --main-color:teal;
  }
.portfolio-section {
  background-color: #2c2d2f;
  width: calc(100% - 200px);
  position: relative;
  left: 200px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .portfolio-section {
    left: 0;
    width: 100%;
    padding-top: 70px;
  }
}
.portfolio-section .portfolio-filter {
  margin-bottom: 30px;
}
.portfolio-section .portfolio-filter ul {
  display: inline-block;
  margin: 0 auto;
  -webkit-border-radius: 27px;
  border-radius: 27px;
  border: 1px dashed rgba(255, 255, 255, 0.2);
  line-height: 0;
  padding-left: 0;
}
.portfolio-section .portfolio-filter ul li.active {
  background-color: var(--main-color);
  -webkit-border-radius: 27px;
  border-radius: 27px;
  color: #2c2d2f;
}
.portfolio-section .portfolio-filter ul li {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  position: relative;
  color: #e1e1e1;
  text-transform: uppercase;
  padding: 20px 20px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio-section .item {
  padding-left: 1em;
  padding-right: 1em;
}
.portfolio-section .item figure {
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  opacity: 0.6;
}
.portfolio-section .item figure:hover {
  opacity: 1;
}
.portfolio-section .item figure:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--main-color);
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.portfolio-section .item figure figcaption {
  position: absolute;
  left: 0;
  top: 50%;
  padding-left: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}
.portfolio-section .item figure:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.portfolio-section .item figure:hover:before {
  opacity: 0.8;
  background-color: var(--main-color);
}

.portfolio-section .item figure:hover figcaption h4,
.portfolio-section .item figure:hover figcaption p {
  opacity: 1;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio-section .item figure figcaption {
  position: absolute;
  left: 0;
  top: 50%;
  padding-left: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
}

.portfolio-section .item figure figcaption h4,
.portfolio-section .item figure figcaption p {
  opacity: 0;
  color: #fff;
  -webkit-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.portfolio-section .item figure figcaption h4 {
  font-size: 20px;
  font-weight: 700;
  -webkit-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  transform: translateY(-50px);
}
.portfolio-section .item figure figcaption p {
  padding-top: 10px;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}
