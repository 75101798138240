:root{
  --main-color:teal;
  }
.resume-section {
  background-color: #2c2d2f;
  width: calc(100% - 200px);
  position: relative;
  left: 200px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .resume-section {
    left: 0;
    width: 100%;
    padding-top: 70px;
  }
}
.resume-section ul.education,
.resume-section ul.experience {
  margin: 20px 0 50px;
  list-style: none;
  position: relative;
  padding: 0 0 0 50px;
  width: 100%;
}
.resume-section ul.education li,
.resume-section ul.experience li {
  position: relative;
  margin-left: 10px;
  padding: 20px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.resume-section ul.education li span.line-left,
.resume-section ul.experience li span.line-left {
  width: 2px;
  height: 100%;
  background-color: var(--main-color);
  left: -10px;
  top: 0;
  position: absolute;
}
.resume-section ul.education li span.line-left:before,
.resume-section ul.education li span.line-left:after,
.resume-section ul.experience li span.line-left:before,
.resume-section ul.experience li span.line-left:after {
  content: "";
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  position: absolute;
  left: -4px;
  top: -10px;
}
.resume-section ul.education li span.line-left:after,
.resume-section ul.experience li span.line-left:after {
  top: 100%;
}
.resume-section .education .content,
.resume-section .experience .content {
  margin-left: 10px;
}

.resume-section .education .content h4,
.resume-section .experience .content h4 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 26px;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}
.resume-section .education .content h5,
.resume-section .experience .content h5 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--main-color);
}
.resume-section .education .content p.info,
.resume-section .experience .content p.info {
  font-size: 16px;
  margin-bottom: 0px;
  color: #fff;
}
.resume-section .education span.year,
.resume-section .experience span.year {
  height: 100%;
  left: -10px;
  top: 0;
  position: absolute;
}
.resume-section .education span.year span,
.resume-section .experience span.year span {
  font-size: 16px;
  position: absolute;
  -webkit-transform: translateX(-120%);
  -ms-transform: translateX(-120%);
  transform: translateX(-120%);
  color: #ffffff;
}
.resume-section .education span.year span.to,
.resume-section .experience span.year span.to {
  top: -18px;
}
.resume-section .education span.year span.from,
.resume-section .experience span.year span.from {
  bottom: -18px;
  text-align: right;
}
.resume-section ul.education li:not(:first-child),
.resume-section ul.experience li:not(:first-child) {
  margin-top: 60px;
}
.resume-section .skills {
  margin-bottom: 30px;
}
.resume-section .skills .skill-item {
  padding: 0 20px;
}
.progress-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px;
  text-transform: uppercase;
  color: #fff;
}
.progress {
  height: 10px;
  background-color: #404144;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 20px;
  overflow: visible;
}
.progress .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  background-color: var(--main-color);
  width: 0;
  -webkit-transition: all 2s ease-out;
  -o-transition: all 2s ease-out;
  transition: all 2s ease-out;
}
.progress .progress-value {
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  top: -30px;
  right: 0;
}
.progress .progress-bar:after {
  content: "";
  display: block;
  border: 15px solid transparent;
  border-bottom: 21px solid transparent;
  position: absolute;
  top: -26px;
  right: -12px;
  border-bottom-color: var(--main-color);
}
